<template>
  <div>
    <b-card-group>
      <b-card-body>
        <b-card
                header="Create"
                header-tag="header"
                class="mb-2"
        >
          <create/>
        </b-card>
      </b-card-body>
    </b-card-group>
    <b-card-group>
      <b-card-body>
        <b-card
                header="#1"
                header-tag="header"
                class="mb-2"
        >
          <edit/>
          <template #footer>
            <small class="text-muted">TimeStamps</small>
          </template>
        </b-card>
      </b-card-body>
    </b-card-group>

  </div>
</template>

<script>
import create from './assets/create.vue'
import edit from './assets/edit.vue'
export default {
  name: "panel",
  components: {create,edit}
}

</script>

<style scoped>

</style>