<template>
  <div>
    <b-input-group prepend="Full URL" class="mt-3">
      <b-form-input disabled/>
      <b-input-group-append>
        <b-button variant="warning">Edit?</b-button>
      </b-input-group-append>
    </b-input-group>
    <b-input-group prepend="https://nammon.men/g/" >
      <b-form-input disabled/>

      <b-input-group-append>
        <b-button variant="danger" @click="showModal">Delete?</b-button>
        <b-modal hide-footer ref="deleted" title="Are You Sure to Delete?">
          <p>Are You Sure to Delete this Short-link<br>If Delete, You CANNOT Recovered it<br></p>
          <b-button variant="success" class="mt-2" block @click="hideModal">Cancel</b-button>
          <b-button variant="danger" class="mt-2" block @click="deleteAction">Delete!</b-button><!--@click=""-->
        </b-modal>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: "edit",
  methods: {
      showModal()
      {
        this.$refs['deleted'].show()
      },
    hideModal(){
        this.$refs['deleted'].hide()
    },
    deleteAction(){
        this.$refs['deleted'].hide()

    }
  }
}
</script>

<style scoped>
</style>