<template>
  <div>
    <!-- Using components -->
    <b-input-group prepend="Full URL" class="mt-3">
      <b-form-input v-model="fullurl"></b-form-input>
      <b-input-group-append>
        <b-button variant="warning" @click="fullurl = null">X</b-button>
      </b-input-group-append>
    </b-input-group>
    <b-input-group prepend="https://nammon.men/g/" >
      <b-form-input v-model="shorturl"></b-form-input>
      <b-input-group-append>
        <b-button variant="warning" @click="shorturl = null">X</b-button>
      </b-input-group-append>
    </b-input-group>
    <b-button variant="success" class="mt-3">Save</b-button>
  </div>
</template>

<script>
export default {
name: "create",
  data(){
    return{
      fullurl: null,
      shorturl: null
    }
  }
}
</script>

<style scoped>

</style>